.modal {
    font-family: Arial, sans-serif;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .parent-item,
  .sub-item {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .parent-item:hover,
  .sub-item:hover {
    background-color: #f0f0f0;
  }
  
  .sub-item {
    margin-left: 20px;
  }
  
  label {
    margin-left: 5px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
  }
  
  /* Define o estilo do sinal de mais (+) e menos (-) */

  